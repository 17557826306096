export const AuthConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGOUT: 'AUTH_LOGOUT',
    ME: 'AUTH_PROFILE',
    FAILED: 'AUTH_FAILED',
    AUTH: 'CHECK_USER_AUTH',
    CLEAR: 'AUTH_CLEAR',
    LOGOUT_FAILED: 'AUTH_LOGOUT_FAILED'
}

export const RoleConstants = {
    GET: 'GET_ROLES',
    ADD: 'ADD_ROLES',
    UPDATE: 'UPDATE_ROLE',
    DELETE: 'DELETE_ROLE',
    FAIL: 'FAIL_ROLES',
    CLEAR: 'CLEAR_ROLES',
    LOADING: 'ROLES_LOADING',
    RESOURCES: 'GET_RESOURCES'
}

export const UserConstants = {
    GET: 'GET_USERS',
    ADD: 'ADD_USER',
    DETAILS: 'GET_USER_DETAILS',
    DELETE: 'DELETE_USER',
    UPDATE: 'UPDATE_USER',
    FAILED: 'USERS_FAILED',
    CLEAR: 'CLEAR_USERS',
}

export const CategoryConstants = {
    GET: 'GET_CATEGORIES',
    ADD: 'ADD_CATEGORY',
    DELETE: 'DELETE_CATEGORY',
    UPDATE: 'UPDATE_CATEGORY',
    FAILED: 'CATEGORIES_FAILED',
    CLEAR: 'CLEAR_CATEGORIES',
}

export const CourseConstants = {
    GET: 'GET_COURSES',
    ADD: 'ADD_COURSE',
    LIST: 'COURSES_LIST',
    DETAILS: 'GET_COURSE_DETAILS',
    UPDATE_SECTION: 'UPDATE_COURSE_SECTION',
    DELETE_SECTION: 'DELETE_COURSE_SECTION',
    ADD_SECTION: 'ADD_COURSE_SECTION',
    DELETE: 'DELETE_COURSE',
    UPDATE: 'UPDATE_COURSE',
    FAILED: 'COURSES_FAILED',
    CLEAR: 'CLEAR_COURSES',
}

export const SectionConstants = {
    ALL: 'GET_SECTIONS',
    GET: 'GET_SECTION',    
    ADD: 'ADD_SECTION',
    SORT: 'SORT_SECTION_ITEMS',
    DELETE: 'DELETE_SECTION',
    UPDATE: 'UPDATE_SECTION',    
    FAILED: 'SECTION_FAILED',
    CLEAR: 'CLEAR_SECTIONS',
    CLEAR_ONE: 'CLEAR_SECTION',
    ADD_ACTIVITY: 'ADD_ACTIVITY',
    UPDATE_ACTIVITY: 'UPDATE_ACTIVITY',    
    REMOVE_ACTIVITY: 'REMOVE_ACTIVITY',
    SORT_ACTIVITIES: 'SORT_ACTIVITIES',
    FAILED_ACTIVITY: 'FAILED_ACTIVITY'
}

export const TopicConstants = {
    GET: 'GET_TOPIC_DETAILS',
    GET_QUESTIONS: 'GET_TOPIC_QUESTIONS',
    ADD: 'ADD_TOPIC_DETAILS',
    UPDATE: 'UPDATE_TOPIC_DETAILS',    
    IMPORT: 'IMPORT_TOPIC_QUESTIONS',
    FAILED: 'TOPIC_FAILED',
    CLEAR: 'CLEAR_TOPICS',
}

export const DiscountConstants = {
    GET_COUPONS: 'GET_COUPONS',
    GET_VOUCHERS: 'GET_VOUCHERS',
    ADD_COUPON: 'ADD_COUPON',
    ADD_VOUCHER: 'ADD_VOUCHER',
    DELETE_COUPON: 'DELETE_COUPON',
    DELETE_VOUCHER: 'DELETE_VOUCHER',
    UPDATE_COUPON: 'UPDATE_COUPON',
    UPDATE_VOUCHER: 'UPDATE_VOUCHER',
    FAILED: 'DISCOUNT_FAILED',
    CLEAR_COUPONS: 'CLEAR_COUPONS',
    CLEAR_VOUCHERS: 'CLEAR_VOUCHERS',
}

export const PlanConstants = {
    GET: 'GET_PLANS',
    ADD: 'ADD_PLAN',
    DELETE: 'DELETE_PLANS',
    UPDATE: 'UPDATE_PLAN',
    FAILED: 'PLANS_FAILED',
    CLEAR: 'CLEAR_PLANS',
}

export const TransactionConstants = {
    GET: 'GET_TRANSACTIONS',
    ADD: 'ADD_TRANSACTION',
    DELETE: 'DELETE_TRANSACTION',
    UPDATE: 'UPDATE_TRANSACTION',
    FAILED: 'TRANSACTION_FAILED',
    CLEAR: 'CLEAR_TRANSACTIONS',
}