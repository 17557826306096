import { PlanConstants as Constants } from '../constants'
const initialState = {
    plans: [],        
};

export function planReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                plans: action.payload.plans
            };
            
        case Constants.ADD:            
            return {
                ...state,
                plans: [...state?.plans, action.payload.plan]
            }

        case Constants.UPDATE:
            let index = state.plans.findIndex((item) => parseInt(item.id) === parseInt(action.payload.plan.id));
            let itemsArray = [...state.plans];
            if(index > -1)
                itemsArray[index] = action.payload.plan
            return {
                ...state,               
                plans: itemsArray
            };           

        case Constants.DELETE:            
            return{
                ...state,
                plans: state.plans.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
            }
                   
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                plans: []
            }

        default:
            return state
    }
}