import { combineReducers } from 'redux'
import { authReducer } from './auth.reducer'
import { categoryReducer } from './category.reducer';
import { courseReducer } from './course.reducer';
import { roleReducer } from './role.reducer'
import { sectionReducer } from './section.reducer';
import { userReducer } from './user.reducer.js'
import { discountReducer } from './discount.reducer';
import { planReducer } from './plan.reducer';
import { transactionReducer } from './transaction.reducer';
import { topicReducer } from './topic.reducer';

export default combineReducers({
    auth: authReducer,
    roles: roleReducer,
    users: userReducer,
    categories: categoryReducer,
    courses: courseReducer,
    sections: sectionReducer,
    topics: topicReducer,
    discounts: discountReducer,
    plans: planReducer,
    transactions: transactionReducer

});
