export const colors = {
    brand: {
        50: "#EEF6FE",
        100: "#CCE5FB",
        200: "#9BC9F7",
        300: "#66A2E8",
        400: "#3F7DD2",
        500: "#0C4DB5",
        600: "#083B9B",
        700: "#062C82",
        800: "#031E68",
        900: "#021556",
    },
    accent:{
        
        50: "#FEF3ED",
        100: "#FCDBC9",
        200: "#F9AF95",
        300: "#EF785F",
        400: "#DF4637",
        500: "#CB0000",
        600: "#AE000F",
        700: "#920019",
        800: "#75001E",
        900: "#610021",
    },
    green:{
        50: "#EEFBCE",
        100: "#EEFBCE",
        200: "#D9F89F",
        300: "#B8EA6C",
        400: "#96D645",
        500: "#68BC13",
        600: "#50A10D",
        700: "#3C8709",
        800: "#2A6D06",
        900: "#1D5A03",
    },
    sidebar: '#122960',
    
    flat:{            
        tomato: "#FB4630",
        pink: "#FE445F",
        purple: "#773DE3",
        indigo: "#B28FF0",
        navy: "#3C50BC",
        blue: "#3389E1",
        aqua: "#00C3ED",
        teal: "#009988",
        green: "#77C33E",
        lime: "#A8E172",
        yellow: "#FFCC34",
        orange: "#FFB718",
        gray: "#616C78"
    },
    muted:{            
        tomato: "rgba(251,70,48, 0.25)",
        pink: "rgba(254,68,95, 0.25)",
        purple: "rgba(119,61,227, 0.25)",
        indigo: "rgba(178,141,240, 0.25)",
        navy: "rgba(60,80,188, 0.25)",
        blue: "rgba(51,137,225, 0.25)",
        aqua: "rgba(0,195,237, 0.25)",
        teal: "rgba(0,189,153, 0.25)",
        green: "rgba(119,195,62, 0.25)",
        lime: "rgba(168,225,114, 0.25)",
        yellow: "rgba(255,204,52, 0.25)",
        orange: "rgba(255,183,24, 0.25)",
        gray: "rgba(97,108,120, 0.25)",
    }      
}