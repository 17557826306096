import React from "react"
import { Box } from "@chakra-ui/react"


export const Header=({children,...rest}) => {
    return(
        <Box px={5} py={3} bg="white" borderBottom="1px solid" borderColor="blackAlpha.100" {...rest}> 
            <>{children}</>
        </Box>
    )
}