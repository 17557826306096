import { UserConstants as Constants } from '../constants'
const initialState = {
    users: [],  
    total: 0,
    user: null      
};

export function userReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                users: action.payload.users,                
                total: action?.payload?.total
            };

        case Constants.DETAILS:
            return {
                ...state,
                user: action.payload.user,                
            };
            
        case Constants.ADD:            
            return {
                ...state,
                users: [...state?.users, action.payload.user]
            }

        case Constants.UPDATE:
            let index = state.users.findIndex((item) => parseInt(item.id) === parseInt(action.payload.user.id));
            let itemsArray = [...state.users];
            if(index > -1)
                itemsArray[index] = action.payload.user
            return {
                ...state,               
                users: itemsArray
            };           

        case Constants.DELETE:            
            return{
                ...state,
                users: state.users.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
            }
                   
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                users: []
            }

        default:
            return state
    }
}