import {requestTimeout, handleResponse} from '../utils'
// import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME

var base_uri = "roles"

export const roleApi = {
    _get,
    _resources,
    _add,
    _delete,
    _update,    
};


// const cookie = new Cookies();
const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)


async function _get() {    
    const requestOptions = {
        method: 'GET',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })

        
}

async function _resources() {        
    const requestOptions = {
        method: 'GET',
        credentials: "include",  
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri+"/resources", requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })

        
}

async function _add(data) {    
        
    const requestOptions = {
        method: 'POST',
        credentials: "include",   
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })                
        
}

async function _update(data, id) {    
        
    const requestOptions = {
        method: 'PUT',
        credentials: "include",   
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri +'/'+id, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })                
        
}

async function _delete(id) {    
        
    const requestOptions = {
        method: 'DELETE',
        credentials: "include",   
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + base_uri +'/'+id, requestOptions))                    
                .then(handleResponse)
                .then((response) => {                        
                    return response
                })                
        
}

