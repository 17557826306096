import React from 'react'
import { FormControl, FormLabel, Text, FormErrorMessage} from '@chakra-ui/react';
import { DatePicker as ChakraDatePicker } from '@yamatomo/chakra-react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { v4 as uuidv4 } from 'uuid';

export const DatePicker = ({label, info, error, required, variant, isClearable=true, size="md", rounded="md",  ...rest}) => {

    let dateRef = React.createRef()
    return (
        <FormControl isInvalid={error} zIndex={10}>
            {label && <FormLabel fontSize="12px" color="gray.400" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} m="0" fontSize="xs" color="red">*</Text>}</FormLabel>}
            {
                info &&
                <Text mt="0"   color="gray.500" fontSize="sm">{info}</Text>
            }
                        
            <ChakraDatePicker forwardRef={dateRef} calendarStartDay={1}  name={uuidv4()} datePickerColorSchema="brand" isClearable={isClearable} inputProps={{ variant: variant || "flushed", size: size, rounded: rounded }} {...rest} />
            
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    )
}