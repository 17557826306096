import React from "react"
import {
    Popover as ChakraPopover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,    
    PopoverArrow,    
    HStack, Box, IconButton,
  } from '@chakra-ui/react'
import { Icon } from "."

export const Popover = React.forwardRef( ({isCloseable, onClose, action, title, children, ...rest}, ref) => {

    return(
        <ChakraPopover {...rest}>
            <PopoverTrigger>
                {action}
            </PopoverTrigger>
            <PopoverContent rounded="none" _focus={{ boxShadow: 'none' }} w="auto" maxW="440px">
                <PopoverArrow />    
                {title && 
                    <PopoverHeader fontWeight={600}>
                        <HStack alignItems="flex=start">
                            <Box flex={1}>{title}</Box>
                            {
                                isCloseable &&
                                <Box>
                                    <IconButton onClick={onClose} size="sm" variant="ghost" rounded="full" colorScheme="gray" icon={<Icon name="close-fill" color="currentcolor" />} />
                                </Box>
                            }

                        </HStack>
                    </PopoverHeader>
                }
                <PopoverBody boxShadow="lg" p={0}>
                    <div  ref={ref} >
                        {children}
                    </div>
                </PopoverBody>
            </PopoverContent>
        </ChakraPopover>
    )
})