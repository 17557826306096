import { SectionConstants as Constants, TopicConstants } from '../constants'
import { topicApi as api } from '../../api'

export const topicAction = {    
    sort_items,
    add,    
    add_questions,
    get_questions,
    import_questions,
    update,
    remove,
    clear,
};

function sort_items(items) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._sort_items(items)
                .then(
                    payload => {
                        dispatch(success(payload));
                        // dispatch(success2(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    // function success2(payload) { return { type: CourseConstants.UPDATE_SECTIONS, payload } }
    function success(payload) { return { type: Constants.SORT_ACTIVITIES, payload } }    
    function failure(error) { return { type: Constants.FAILED_ACTIVITY, error } }
}

function add(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.ADD_ACTIVITY, payload } }    
    function failure() { return { type: Constants.FAILED_ACTIVITY } }
    }
}

function add_questions(form_data, topic_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add_questions(form_data, topic_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: TopicConstants.IMPORT, payload } }    
    function failure() { return { type: TopicConstants.FAILED } }
    }
}

function get_questions(topic_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get_questions(topic_id)
                .then(
                    payload => {                        
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: TopicConstants.GET_QUESTIONS, payload } }    
    function failure() { return { type: TopicConstants.FAILED } }
    }
}

function import_questions(form_data, topic_id) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._import(form_data, topic_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: TopicConstants.ADD, payload } }    
    function failure() { return { type: TopicConstants.FAILED } }
    }
}

function update(form_data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update(form_data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE_ACTIVITY, payload } }
    function failure(error) { return { type: Constants.FAILED_ACTIVITY, error } }
}

function remove(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REMOVE_ACTIVITY, payload } }
    function failure(error) { return { type: Constants.FAILED_ACTIVITY, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR_ONE } }
}