import { CourseConstants as Constants } from '../constants'

const initialState = {
    courses: [],     
    course: null,
    list: [] 
};

export function courseReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                courses: action.payload.courses
            };

        case Constants.LIST:            
            return {
                ...state,
                list: action.payload.courses
            };

        case Constants.DETAILS:
            return {
                ...state,
                course: action.payload.course
            };
            
        case Constants.ADD:            
            return {
                ...state,
                courses: [...state?.courses, action.payload.course]
            }

        case Constants.UPDATE:
            let index = state.courses.findIndex((item) => parseInt(item.id) === parseInt(action.payload.course.id));
            let itemsArray = [...state.courses];
            if(index > -1)
                itemsArray[index] = action.payload.course
            return {
                ...state,               
                courses: itemsArray
            };           

        case Constants.UPDATE_SECTION:       
        
            let section_index = state.course.sections.findIndex((item) => parseInt(item.id) === parseInt(action.payload.section.id));
            let sectionsArray = [...state.course.sections];
            if(section_index > -1)
                sectionsArray[section_index] = action.payload.section

            return {
                ...state,
                    course: {
                        ...state?.course,
                        sections: sectionsArray
                }
            }

        case Constants.DELETE_SECTION:       
            
            let sections_array = state.course.sections.filter((x) => parseInt(x.id) !== parseInt(action.payload.id))
            return {
                ...state,
                    course: {
                        ...state?.course,
                        sections: sections_array
                }
            }

        case Constants.DELETE:            
            return{
                ...state,
                courses: state.courses.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
            }
                   
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                courses: []
            }

        default:
            return state
    }
}