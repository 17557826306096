import { TransactionConstants as Constants } from '../constants'

const initialState = {
    transactions: [],        
    total: 0,
};

export function transactionReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                transactions: action.payload.transactions,
                total: action.payload.total
            };
        
            
        case Constants.ADD:
            return {
                ...state,
                transactions: [...state?.transactions, action.payload.transaction]
            }

        

        case Constants.UPDATE:
            let index = state.transactions.findIndex((item) => parseInt(item.id) === parseInt(action.payload.transaction.id));
            let itemsArray = [...state.transactions];
            if(index > -1)
                itemsArray[index] = action.payload.transaction
            return {
                ...state,               
                transactions: itemsArray
            };           

            

        case Constants.DELETE:  
            return{
                ...state,
                transactions: state.transactions.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
            }

      
                   
        case Constants.FAILED:
            return{
                ...state
            }

       

        case Constants.CLEAR:
            return {
                ...state,
                transactions: [],
                total: 0
            }

        default:
            return state
    }
}