import React, { useState } from 'react'
import { HStack, Box, Text } from '@chakra-ui/react'



const TabItem = ({label, value, isActive,  onChangeTab, ...props}) => {

    return(
        <Box color="gray.700" onClick={() => onChangeTab(value)} cursor="pointer" px="5" py="3" borderBottom="2px solid" borderColor={isActive ? 'brand.400' : 'transparent'} >
            <Text textTransform="uppercase" color="currentcolor" fontWeight="600" fontSize="12px">{label}</Text>
        </Box>
    )
} 


const Tabs = ({children, onChangeTab, index = 0 ,  ...rest}) => {

    const [ current, setCurrent ] = useState(index)

    const childrenWithProps = React.Children.map(children, (child, i) => {
        // Checking isValidElement is the safe way and avoids a typescript
        // error too.
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { 
              isActive: current === i, 
              value: i,
              onChangeTab: (i) => {
                setCurrent(i)
                onChangeTab(i)
              } 
            });
        }
        return child;
      });
    

    return(
        <HStack px={5}  alignItems="flex-start" spacing="5" borderBottom="1px solid" borderTop="1px solid" borderColor="gray.200" {...rest}>
            {childrenWithProps}        
        </HStack>
    )
}

Tabs.TabItem = TabItem
export { Tabs }