import React, { useEffect, useState } from 'react'
import { Box, HStack, VStack, FormLabel, Text, IconButton } from '@chakra-ui/react'
import { Icon, Tooltip } from '.'

const DocumentPicker = ({multiple=false, error, placeholder, info, label, accept,  onSelect, docs =[]}) => {

    
    const [list, setList] = useState(null)
    const fileRef = React.useRef();

   useEffect(() => {
        if(!multiple && docs?.length === 0){
            setList(null)
        }else{
            setList(docs)
        }        
   }, [docs, multiple])


    const handleFile = (e) => {
        if(e.target.files){
            if(multiple){
                let allFiles  = e.target.files
                let allList = []
                for (var i = 0; i < allFiles.length; i++) {
                        allList.push({
                            name: allFiles[i].name,
                            file: allFiles[i]
                        })
                }                
                setList([...list, ...allList ])
                onSelect([...list, ...allList ])
            }
            else{
                let file  = e.target.files[0]                
                setList([{
                    name: file.name,
                    file: file,
                }])
                onSelect({name: file.name, file: file})
            }
        }else{
            alert("Please select file(s)")
        }  
    }

    const triggerSelect = () =>{
        var event = new MouseEvent('click', {
            'view': window, 
            'bubbles': true, 
            'cancelable': false
           });           
           fileRef.current.dispatchEvent(event);
    }

    const removeFile = (index) => {
        let allFiles = [...list]
        if(!multiple){
            setList([])
            onSelect(null)
        }else{
            allFiles.splice(index, 1)
            setList(allFiles)
            onSelect(allFiles)
        }
    }

    
    return(
        <>
        <HStack py="0.25" mb={2} spacing={1} alignItems="center">
            <Box>{label && <FormLabel mb="0" fontSize="12px" color="gray.400" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}</Box>            
            <Tooltip label='Select files to upload'  placement="end">
                <IconButton onClick={triggerSelect} rounded="full" variant="ghost"  colorScheme="brand" size="xs" icon={<Icon fontSize={12} color="currentcolor" name="upload-fill" />} />
            </Tooltip>
            <input multiple={multiple ? true : false}   accept={accept || "*/*"} style={{  display: "none" }} ref={fileRef} type="file" onChange={handleFile}/>
        </HStack>   
        {
            info &&
            <Text mb="4" color="gray.500" fontSize="sm">{info}</Text>
        }
        {
            error &&
            <Text fontSize="sm" color="red">{error}</Text>
        }             
        <Box rounded="md">
            
            { 
                (!list || list.length === 0 ) ?                    
                    placeholder &&
                    <Box onClick={triggerSelect} cursor="pointer" textAlign="center" bg="gray.100" p="6" rounded="md">
                        <Text fontSize="sm">{placeholder}</Text>
                    </Box>
                    
                :
                <Box py="3">
                    <VStack alignItems="flex-start">
                        {
                            (list?.length > 0) && list.map((item, t) => {
                                return(
                                    <HStack pl="2" bg="gray.100" rounded="md" w="100%"  spacing={5}  key={t} justifyContent="space-between" py="2" _hover={{ bg: 'gray.200', color: 'gray.900' }}>
                                        <HStack flex={1}>
                                            <Icon name="file-unknow-fill" color="green.700" />
                                            <Text fontSize="sm" color="currentcolor" noOfLines={0}>{item?.name}</Text>
                                        </HStack>
                                        <IconButton mr={5} onClick={() => removeFile(t)} size="xs" rounded="full" colorScheme="red" variant="ghost" icon={<Icon name="close-circle-fill" color="currentcolor" />} />
                                    </HStack>
                                )
                            })                            
                        }
                    </VStack>
                </Box>
            }
        </Box>        
        </>
    )
}

export { DocumentPicker }