import React from 'react'
import { Box, Image, Heading, Text } from '@chakra-ui/react'

const NoData = ({size, title, flex, text, visible, children,  ...rest}) => {

    if(visible)
    {
        return(        
            <Box display="flex" flex="1" flexDir="column"  p="2" alignItems="center" justifyContent="center">
                <Image src="/assets/no-data.svg" width={size === "sm" ? "60px" : "150px"} h="auto" alt={title} title={title} />
                <Heading mt="5" mb="0" fontWeight="700"  color="gray.700" fontSize={size === "sm" ? "14px" : "20px"}>{title}</Heading>
                <Text mt={2} fontSize={size === "sm" ? "12px" : "14px"} color="gray.500">{text}</Text>
                {children}
            </Box>
        )
    }
    return(<></>)
}

export { NoData }