import React from 'react'
import { HStack, Box, Text,
    FormControl, FormLabel, IconButton, InputLeftElement,
    InputGroup, Input, InputRightElement,
    FormErrorMessage,     
    useOutsideClick,
} from '@chakra-ui/react'


import { Icon } from '.'


export const FilterSelect = ({error, label, info, required, items, onClose, selected,  onSelect, ...rest}) => {

    const dropdownRef = React.useRef()

    useOutsideClick({
        ref: dropdownRef,
        handler: () => {
            onClose()
        }
    })



    return(
        <Box m="0">
            <FormControl isInvalid={error}>
                {label && <FormLabel  fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} { required && <Text as="span" fontSize="sm" color="red">*</Text>}</FormLabel>}
                {info && <Text mb="4" color="gray.500" fontSize="sm">{info}</Text> }
                <Box pos="relative" ref={dropdownRef}>
                    <InputGroup>
                        <InputLeftElement justifyContent="flex-start">
                            <Icon name="search-line" fontSize="sm"  color="gray.500" />
                        </InputLeftElement>
                        <Input                                                     
                            autoComplete="off"                              
                            {...rest}
                        />
                        <InputRightElement>
                            <HStack>                                
                                {
                                    rest?.value &&
                                    <IconButton 
                                        onClick={() => onSelect(null)} 
                                        rounded="full" 
                                        variant="ghost" 
                                        colorScheme="gray" 
                                        size="xs" 
                                        icon={<Icon fontSize="18px" name="close-line" color="currentcolor" />} 
                                    />
                                }                                
                            </HStack>
                        </InputRightElement>
                    </InputGroup>
                    {
                        (items?.length > 0 && !selected) &&
                        <Box bg="white" zIndex="popover" shadow="custom" pos="absolute" left={0} right={0} top="100%">
                            {
                                items?.map((item, i) =>
                                    <Box cursor="pointer" _hover={{ bg: "gray.100" }} px={5} py={2} onClick={() => onSelect(item)} key={i}>{item?.label}</Box>
                                )
                            }
                        </Box>
                    }
                </Box>
                <FormErrorMessage>{error}</FormErrorMessage>
            </FormControl>
        </Box>
    )
}