import React, { useState, useEffect } from 'react'
import { Box, HStack, IconButton, Button, Text } from '@chakra-ui/react'
import { Icon } from '../../@uikit'

export const Pagination = ({ onChange, pageSize, total, dataLabel, ...rest }) => {

    const LEFT_PAGE = 'LEFT';
    const RIGHT_PAGE = 'RIGHT';
    const [current, setCurrent] = useState(1)
    const [pages, setPages] = useState(0)
    const pageNeighbours = 2;

    const handleChange = (index) => {
        setCurrent(index)
        onChange(index, pageSize)
    }

    useEffect(() => {
        setPages(Math.ceil(total / pageSize));
        setCurrent(1)
    }, [total, pageSize])

    const range = (from, to, step = 1) => {
        let i = from;
        const range = [];

        while (i <= to) {
            range.push(i);
            i += step;
        }

        return range;
    }

    const handlePrevious = (e) => {
        e.preventDefault();
        let jump = current - 1;
        handleChange(jump < 1 ? 1 : jump);
    }

    const handleNext = (e) => {
        e.preventDefault();
        handleChange(current + 1);
    }

    const handleMoveLeft = (e) => {
        e.preventDefault();
        let jump = current - (pageNeighbours * 2) - 1;
        handleChange(jump < 1 ? 1 : jump);
    }

    const handleMoveRight = (e) => {
        e.preventDefault();
        handleChange(current + (pageNeighbours * 2) - 1);
    }

    const getPageNumbers = () => {
        const totalPages = pages;
        const currentPage = current;
        const totalNumbers = (pageNeighbours * 2) + 3;
        const totalBlocks = totalNumbers + 2;
        let all_pages = [];
        if (totalPages > totalBlocks) {
            const startPage = Math.max(2, currentPage - pageNeighbours);
            const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
            all_pages = range(startPage, endPage);
            const hasLeftSpill = startPage > 2;
            const hasRightSpill = (totalPages - endPage) > 1;
            const spillOffset = totalNumbers - (all_pages.length + 1);
            switch (true) {
                // handle: (1) < {5 6} [7] {8 9} (10)
                case (hasLeftSpill && !hasRightSpill): {
                    const extraPages = range(startPage - spillOffset, startPage - 1);
                    all_pages = [LEFT_PAGE, ...extraPages, ...all_pages];
                    break;
                }

                // handle: (1) {2 3} [4] {5 6} > (10)
                case (!hasLeftSpill && hasRightSpill): {
                    const extraPages = range(endPage + 1, endPage + spillOffset);
                    all_pages = [...all_pages, ...extraPages, RIGHT_PAGE];
                    break;
                }

                // handle: (1) < {4 5} [6] {7 8} > (10)
                case (hasLeftSpill && hasRightSpill):
                default: {
                    all_pages = [LEFT_PAGE, ...all_pages, RIGHT_PAGE];
                    break;
                }
            }
            all_pages = [1, ...all_pages, totalPages];
        } else {
            all_pages = range(1, totalPages);
        }


        let elements = []
        all_pages.map((page, index) => {
            

            if (page === LEFT_PAGE) {
                return elements.push(
                    <IconButton size="xs" key={index} onClick={handleMoveLeft} rounded="none" variant="ghost" colorScheme="gray" icon={<Icon color="currentcolor" fontSize="15px" name="more-fill" />} />
                )
            }
            if (page === RIGHT_PAGE) {
                return elements.push(
                    <IconButton size="xs" key={index} onClick={handleMoveRight} rounded="none" variant="ghost" colorScheme="gray" icon={<Icon color="currentcolor" fontSize="15px" name="more-fill" />} />
                )
            }
            return elements.push(
                <Button
                    variant="unstyled"
                    key={index}
                    size="xs"
                    color={current === page ? "white" : "gray.600"}
                    bg={current === page ? "brand.500" : "white"}                    
                    rounded="full"                                     
                    onClick={() => handleChange(page)}
                    >
                    {page}
                </Button>
                // <Button key={index} rounded="none" onClick={() => handleChange(page)} colorScheme={current === page ? 'admin' : 'gray'} bg={current !== "page" && "white" } size="xs" d="block" variant={current === page ? "solid" : "outline"}>{page}</Button>
            )

        })
        return elements;
    }

    
    
    return (
        <Box>
            {pages > 1 &&
                <HStack spacing="5" justifyContent="space-between">
                    <Box>
                        {
                            dataLabel ?
                            <Text fontSize="14px" fontWeight="400"><strong>{current }</strong> of <strong>{total }</strong> { dataLabel}</Text>
                            :
                            <Text fontSize="14px" fontWeight="600">of {total } records found</Text>
                        }
                        
                    </Box>
                    <HStack spacing={1}>
                        {
                            current > 1 &&
                            <Button
                                variant="unstyled"
                                size="xs"
                                color={"gray.600"}
                                bg={"white"}
                                rounded="full"                                
                                onClick={handlePrevious}
                                >
                            <Icon color="currentcolor" fontSize="16px" name="arrow-left-s-fill" />
                        </Button>

                        }
                        {getPageNumbers()}

                        {
                            (current < pages) &&
                            <Button
                                variant="unstyled"
                                size="xs"
                                color={"gray.600"}
                                bg={"white"}
                                rounded="full"                          
                                onClick={handleNext}
                                >
                            <Icon color="currentcolor" fontSize="16px" name="arrow-right-s-fill" />
                        </Button>

                        }  
                    </HStack>                  
                </HStack>
            }
        </Box>
    )
}
