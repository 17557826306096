import { DiscountConstants as Constants } from '../constants'

const initialState = {
    coupons: [],        
    vouchers: [],
    total: 0,
};

export function discountReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET_COUPONS:
            return {
                ...state,
                coupons: action.payload.coupons
            };

        case Constants.GET_VOUCHERS:
            return {
                ...state,
                vouchers: action.payload.vouchers,
                total: action.payload.total
            };
            
        case Constants.ADD_COUPON:            
            return {
                ...state,
                coupons: [...state?.coupons, action.payload.coupon]
            }

        case Constants.ADD_VOUCHER:            
            return {
                ...state,
                vouchers: [...state?.vouchers, action.payload.voucher]
            }

        case Constants.UPDATE_COUPON:
            let index = state.coupons.findIndex((item) => parseInt(item.id) === parseInt(action.payload.coupon.id));
            let itemsArray = [...state.coupons];
            if(index > -1)
                itemsArray[index] = action.payload.coupon
            return {
                ...state,               
                coupons: itemsArray
            };           

        case Constants.UPDATE_VOUCHER:
            let v_index = state.vouchers.findIndex((item) => parseInt(item.id) === parseInt(action.payload.voucher.id));
            let v_itemsArray = [...state.vouchers];
            if(v_index > -1)
                v_itemsArray[v_index] = action.payload.voucher
            return {
                ...state,               
                coupons: v_itemsArray
            };           

        case Constants.DELETE_COUPON:            
            return{
                ...state,
                coupons: state.coupons.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
            }

        case Constants.DELETE_VOUCHER:            
            return{
                ...state,
                vouchers: state.vouchers.filter((x) => parseInt(x.id) !== parseInt(action.payload.id)),
            }
                   
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR_COUPONS:
            return {
                ...state,
                coupons: []
            }

        case Constants.CLEAR_VOUCHERS:
            return {
                ...state,
                vouchers: []
            }

        default:
            return state
    }
}