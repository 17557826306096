import React from "react"
import { Box } from "@chakra-ui/react"
import { Indicator, NoData } from "../../@uikit"

export const Loader = ({size, variant, isLoading, notFound,  title,  children, ...rest}) => {
    
    if(isLoading){
        return(
            <Box display="flex" flex="1" h="100%" flexDirection="column" position="relative">
                {
                    isLoading &&            
                    <Box zIndex="overlay" bg="rgba(15,30,73,0.07)" display="flex" alignItems="center" justifyContent="center"  position="absolute" left={0} top={0} w="100%" h="100%">
                        <Indicator size={size || "md"} variant={variant || "typing"} />                    
                    </Box>
                }                                
            </Box>
        )
    }

    else if(notFound){
        return(
            <Box display="flex" flex="1" h="100%" flexDirection="column" position="relative">
                <NoData visible title={title} text="No results found. Please try later or refresh the page"  />
            </Box>
        )
    }
    return(<>{children}</>)
        
}