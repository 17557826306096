import React, { useState, useEffect } from 'react'
import { Box, HStack, Image, Text, FormLabel, IconButton } from '@chakra-ui/react'
import {  Icon, Tooltip } from '.'

const ImagePicker = ({multiple=false, placeholder, error, label, onSelect,  images=[]}) => {

    const [list, setList] = useState(images || [])
    const imgRef = React.useRef();
    const rowRef = React.useRef();
    const [ boxSize, setBoxSize ]  = useState(100)

    useEffect(() => {
        if(images?.length > 0 && rowRef?.current){
            if(rowRef?.current?.clientWidth > 0){
                let width = rowRef?.current?.clientWidth / 4;
                console.log("Width", width)
                setBoxSize(Math.round(width) - 15)
            }                    
        }
    }, [images])

    const handleFile = (e) => {
        if(e.target.files){
            if(multiple){
                let allFiles  = e.target.files
                let allList = []
                for (var i = 0; i < allFiles.length; i++) {
                    if(allFiles[i]?.type.includes('image/')){
                        allList.push({
                            thumb: URL.createObjectURL(allFiles[i]),
                            file: allFiles[i]
                        })
                    }    
                }                
                setList([...list, ...allList ])
                onSelect([...list, ...allList ])
            }
            else{
                let file  = e.target.files[0]
                if(file?.type.includes('image/')){
                    let img = URL.createObjectURL(file)
                    setList([{
                        thumb: img,
                        file: file,
                    }])
                    onSelect({thumb: img, file: file})
                }
                else{
                    alert("Only image files are allowed")
                }
            }
        }else{
            alert("Please select image file")
        }
    }

    const triggerSelect = () =>{
        var event = new MouseEvent('click', {
            'view': window, 
            'bubbles': true, 
            'cancelable': false
           });           
           imgRef.current.dispatchEvent(event);
    }

    const removeImage = (index) => {
        let allData = [...list]
        allData.splice(index, 1)
        setList(allData)
        onSelect(allData)
    }
    
    return(
        <>
        <HStack py="2" alignItems="center">
            <Box>{label && <FormLabel m="0" fontSize="12px" color="gray.400" fontWeight="600" textTransform="uppercase">{label}</FormLabel>}</Box>
            <Tooltip label='Select Images to upload' placement="end">
                <IconButton onClick={triggerSelect} rounded="full" variant="ghost" _hover={{ bg: 'brand.400', color: 'white' }} colorScheme="brand" size="xs" icon={<Icon fontSize="sm" color="currentcolor" name="image-fill" />} />
            </Tooltip>
            <input multiple={multiple ? true : false}  accept="image/*" style={{  display: "none" }} ref={imgRef} type="file" onChange={handleFile}/>
        </HStack>   
        {
            error &&
            <Text fontSize="sm" color="red">{error}</Text>
        }     
        <Box>
            
            { 
                (list.length === 0 || !list) ?
                <>
                    {
                        placeholder &&
                        <Box onClick={triggerSelect} textAlign="center" bg="gray.100" p="6" rounded="md">
                            <Text fontSize="sm">{placeholder}</Text>
                        </Box>
                    }
                </>
                :
                <Box ref={rowRef}>
                    <HStack flexWrap="wrap" mx="-10px" spacing="0">
                        {
                            list.map((item, t) => 
                            <Box p="10px" key={t}>
                                <Box pos="relative" className="hover-reveal">
                                    <Image boxSize={boxSize+'px'} src={item?.thumb} objectFit="cover" rounded="md" />
                                    <Box className="hover-target" display="none" pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
                                        <IconButton onClick={() => removeImage(t)} size="sm" rounded="full" colorScheme="red"  icon={<Icon name="ios-close" color="currentcolor" />} />
                                    </Box>
                                </Box>
                            </Box>
                            )
                        }
                    </HStack>
                </Box>
            }
        </Box>
        </>
    )
}

export { ImagePicker }