import {handleResponse, requestTimeout} from '../utils'
// import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
// const cookie = new Cookies();


export const discountApi = {
    _get_coupons,
    _get_vouchers,
    _add_coupon,
    _add_voucher,
    _update_coupon,
    _delete_coupon,
};



async function _get_coupons() {    

    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    
    const requestOptions = {
        method: 'GET',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}

async function _get_vouchers(offset, filters, page_size) {    

    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    
    const requestOptions = {
        method: 'POST',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        },
        body: JSON.stringify(filters)        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'vouchers/'+offset+'/'+page_size, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _add_coupon(form_data) {
    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)

    const requestOptions = {
        method: 'POST',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}


async function _add_voucher(form_data) {
    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)

    const requestOptions = {
        method: 'POST',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'vouchers', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _update_coupon(form_data, id) {

    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)

    const requestOptions = {
        method: 'PUT',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete_coupon(id) {
    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)

    const requestOptions = {
        method: 'DELETE',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'coupons/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}