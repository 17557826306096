import { RoleConstants as Constants } from '../constants'
import { roleApi as api } from '../../api'

export const roleAction = {
    get,
    resources,
    add,
    update,
    remove,
    clear    
};

function get() {
    return dispatch => {
        dispatch(loading())
        return new Promise((resolve, reject) => {
            api._get()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure(error));
                        // reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function loading() { return { type: Constants.LOADING } }
    function failure(error) { return { type: Constants.FAIL, error } }
}

function resources() {
    return dispatch => {
        // dispatch(loading())
        return new Promise((resolve, reject) => {
            api._resources()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure(error));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.RESOURCES, payload } }
    // function loading() { return { type: Constants.LOADING } }
    function failure(error) { return { type: Constants.FAIL, error } }
}

function add(data) {
    return dispatch => {        
        return new Promise((resolve, reject) => {
            api._add(data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.ADD, payload } }
    function failure(error) { return { type: Constants.FAIL, error } }
}

function update(data, id) {    
    return dispatch => {
        dispatch(loading())
        return new Promise((resolve, reject) => {
            api._update(data,id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure(error));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function loading() { return { type: Constants.LOADING } }
    function failure(error) { return { type: Constants.FAIL, error } }
    }
}

function remove(id) {    
    return dispatch => {
        dispatch(loading())
        return new Promise((resolve, reject) => {
            api._delete(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure(error));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.DELETE, payload } }
    function loading() { return { type: Constants.LOADING } }
    function failure(error) { return { type: Constants.FAIL, error } }
    }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}