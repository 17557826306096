import { RoleConstants as Constants } from '../constants'

const initialState = {
    roles: [],
    resources:[],
    error: null,    
    loading: false   
};

export function roleReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:            
            return {
                ...state,
                roles: action?.payload?.roles,
                loading: false,
                error: null,
            };

        case Constants.RESOURCES:
            return {
                ...state,
                resources: action?.payload?.resources,
                loading: false,
                error: null,
            };
            
        case Constants.ADD:
            return {
                ...state,
                roles: [...state?.roles, action?.payload?.role],
                loading: false,
                error: null,
            }

        case Constants.UPDATE:            
            const index = state.roles.findIndex(x => x.id ===  action?.payload?.role?.id)
            const newArray = [...state.roles];
            if(index > -1)
                newArray[index] = action?.payload?.role
            return {
                ...state,
                roles: newArray,
                loading: false,
                error: null,
            }   

        case Constants.DELETE:
            return{
                ...state,
                roles: state?.roles?.filter(x => parseInt(x?.id) !== parseInt(action?.payload?.id)),
                loading: false,
                error: null,
            };
       
        
        case Constants.FAIL:
            return{
                ...state,
                error: action?.error,
                loading: false
            }
        
        case Constants.LOADING:
            return{
                ...state,
                error: null,
                loading: true
            }

        case Constants.CLEAR:
        default:
            return state
    }
}