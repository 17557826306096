import React from 'react'
import { Box, FormLabel, FormControl, Text, FormErrorMessage } from '@chakra-ui/react'
import ReactQuill from 'react-quill'

import 'react-quill/dist/quill.snow.css'; 

const RichTextarea = React.forwardRef( ({error, required, scheme, showerror=false, size, label, ...rest}, ref) => {


    const modules = {            
        toolbar: {                   
            container:[       
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            //   [{ 'size': [ 'small', false, 'large', 'huge' ]}],
            ['bold', 'italic', 'underline','strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, {'align': []}],
            [{'color': []}, {'background': []}],  
            ['link', 'image'],
            ['clean'],
        ],
        }
    }
    
    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'align',
        'color', 'background',
        'link', 'image', 'code-block'
      ]

    
    return(
        <Box>
            <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.500" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} m="0" fontSize="xs" color="red">*</Text>}</FormLabel>}
            {showerror && <FormErrorMessage>{error}</FormErrorMessage>}
            <ReactQuill modules={modules} formats={formats} ref={ref}  {...rest} />            
            </FormControl>
        </Box>
    )
})

export { RichTextarea}