import {handleResponse, requestTimeout} from '../utils'
// import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
// const cookie = new Cookies();

export const sectionApi = {
    _get,
    _sort_items,
    _add,
    _update,
    _delete,
};

async function _get(id) {    
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'GET',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    ;
}

async function _sort_items(items) {    
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({items})      
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/reorder', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    ;
}


async function _add(form_data) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _update(form_data, id) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'DELETE',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}