import {handleResponse, requestTimeout} from '../utils'
// import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
// const cookie = new Cookies();

export const topicApi = {
    // _get,
    _sort_items,
    _import,
    _add,
    _add_questions,
    _get_questions,
    _update,
    _delete,
};



// async function _get(id) {    
//     let token = localStorage.getItem(accessToken)
//     const requestOptions = {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json',
//             'Accept': 'application/json',
//             'Authorization': 'Bearer ' + token
//         }        
//     };
//     return requestTimeout(apiMaxTime,fetch(apiUrl + 'sections/'+id, requestOptions))
//                 .then(handleResponse)
//                 .then((response) => {                    
//                     return response
//                 });
//     ;
// }

async function _sort_items(items) {    
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'PUT',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        },
        body: JSON.stringify({items})      
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/reorder', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                }); 
}

async function _import(form_data, topic_id) {    
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',            
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/import/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    
}


async function _add(form_data) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Accept': 'application/json',            
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _add_questions(form_data, topic_id) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        },
        body: JSON.stringify(form_data)
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/questions/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _get_questions(topic_id) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'GET',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',            
        }        
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/questions/'+topic_id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}

async function _update(form_data, id) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Accept': 'application/json'
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    const token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'DELETE',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'section/topics/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}