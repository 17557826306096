import { SectionConstants as Constants, CourseConstants } from '../constants'
import { sectionApi as api } from '../../api'

export const sectionAction = {
    get,
    sort_items,
    add,
    update,
    remove,
    clear,
};

function get(course_id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(course_id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function sort_items(items) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._sort_items(items)
                .then(
                    payload => {
                        dispatch(success(payload));
                        dispatch(success2(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success2(payload) { return { type: CourseConstants.UPDATE_SECTIONS, payload } }
    function success(payload) { return { type: Constants.SORT, payload } }    
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        // dispatch(success2(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.ADD, payload } }
    function success2(payload) { return { type: CourseConstants.ADD_SECTION, payload } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function update(form_data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update(form_data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        dispatch(success2(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }    
    function success(payload) { return { type: CourseConstants.UPDATE_SECTION, payload } }
    function success2(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        dispatch(success2(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success2(payload) { return { type: CourseConstants.DELETE_SECTION, payload } }
    function success(payload) { return { type: Constants.DELETE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}