import React from 'react'
import {
    FormControl,
    FormLabel,    
    Text,
    Box,
    FormErrorMessage,
    NumberInput as ChakraNumberInput,
    NumberInputField
  } from "@chakra-ui/react"
  
const NumberInput = React.forwardRef( ({error, info, prefix, suffix, onChange,  placeholder, value, required=false,  label, ...props}, ref) => {
    const format = (val) => {
        if(val){
            val = val + ""
            return val.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1,`)
        }
    }
    const parse = (val) => val.replace(/^,|,$/g, ``)

    return(
        <FormControl isInvalid={error}>
            {label && <FormLabel fontSize="12px" color="brand.600" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} fontSize="xs" color="red">*</Text>}</FormLabel>}

                <Box  position="relative">
                {
                    prefix &&
                    <Text position="absolute" fontSize="sm" color="gray.500" left={0} top={'50%'} transform="translateY(-50%)">
                        {prefix}
                    </Text>
                }

                {
                    suffix &&
                    <Text position="absolute" fontSize="sm" color="gray.500" right="5px" top={'50%'} transform="translateY(-50%)">
                        {suffix}
                    </Text>
                }
                <ChakraNumberInput 
                    // pattern="[0-9 _,]*"
                    onChange={(valueString) => onChange(parse(valueString))}
                    value={format(value)}
                    
                    // onChange = {(val) => onChange(val)}
                    // value={value}
                    focusBorderColor='brand.700' {...props} ref={ref}>
                    <NumberInputField pr={suffix ? '20px' : 0} pl={prefix ? '20px' : 0} placeholder={placeholder || 0}  />                
                </ChakraNumberInput>
                </Box>
                {
                    info && <Text fontSize="xs" color="gray.500">{info}</Text>
                }
            <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
    )
})
export {NumberInput}