import React from "react"
import { Box, Image } from "@chakra-ui/react"
import { Tooltip } from '.'



export const LinkBox = ({icon, w, p, placement="top", boxSize="90%", color, label, ...rest}) => {
    return(
        <Box p={p || 1} w={w || "56px"}>
            <Tooltip label={label} placement={placement}>
            <Box  opacity={1} _hover={{ opacity: 0.9 }}  rounded="4px" cursor="pointer" {...rest}>
                {
                    icon &&
                    // <Icon fontSize={20} color="gray.400"  name={icon} />
                    <Image boxSize={boxSize}  src={'/assets/icon-'+icon+'.svg'} />
                }                
            </Box>    
            </Tooltip>        
        </Box>
    )
}