import {handleResponse, requestTimeout} from '../utils'
// import { Cookies } from 'react-cookie'

const apiUrl = process.env.REACT_APP_API_URL
const apiMaxTime = process.env.REACT_APP_API_EXEC_TIME
// const cookie = new Cookies();

export const courseApi = {
    _get,    
    _list,
    _details,
    _add,
    _import_questions,
    _update,
    _delete,
};

async function _get() {    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    
    const requestOptions = {
        method: 'GET',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}

async function _list() {    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'GET',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'            
        }        
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses-list', requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _details(slug) {    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    
    const requestOptions = {
        method: 'GET',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }        
    };

    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+slug, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
    return result;
}


async function _add(form_data) {
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',            
        },
        body: form_data
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses', requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _import_questions(form_data, course_id) {
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    const requestOptions = {
        method: 'POST',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',            
        },
        body: form_data
    };
    let result = await requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/import-questions/'+course_id, requestOptions))
                    .then(handleResponse)
                    .then((response) => {                    
                        return response
                    });
    return result;
}

async function _update(form_data, id) {

    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)

    const requestOptions = {
        method: 'POST',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            // 'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: form_data
    };
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}


async function _delete(id) {
    
    let token = localStorage.getItem(process.env.REACT_APP_AUTH_COOKIE)
    
    const requestOptions = {
        method: 'DELETE',
        
        headers: {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }        
    };    
    return requestTimeout(apiMaxTime,fetch(apiUrl + 'courses/'+id, requestOptions))
                .then(handleResponse)
                .then((response) => {                    
                    return response
                });
}