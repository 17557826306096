import { SectionConstants as Constants } from '../constants'

const initialState = {
    sections: [],
    section: null,
};

export function sectionReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET:
            return {
                ...state,
                section: action.payload.section
            };
            
        case Constants.ADD:            
            return {
                ...state,
                sections: [...state?.sections, action.payload.section]
            }

        case Constants.SORT:            
            return {
                ...state,
                sections: action.payload.sections
            }

        case Constants.SORT_ACTIVITIES:            
        return {
            ...state,
                section: {
                    ...state?.section,
                    topics:action?.payload?.topics
            }
        }

        case Constants.UPDATE:
            // let index = state.sections.findIndex((item) => item.id === action.payload.section.id);
            // let itemsArray = [...state.sections];
            // if(index > -1)
            //     itemsArray[index] = action.payload.section
            return {
                ...state,               
                section: action?.payload?.section
            };           

        case Constants.DELETE:            
            return{
                ...state,
                sections: state.sections.filter((x) => x.id !== action.payload.id)
            }
                  
        case Constants.ADD_ACTIVITY:            
            return {
                ...state,
                    section: {
                        ...state?.section,
                        topics:[                            
                            ...state?.section?.topics,
                            action.payload.topic,
                        ]
                }
            }

        case Constants.UPDATE_ACTIVITY:            
            let index = state?.section?.topics?.findIndex((item) => item.id === action.payload.topic.id);
            let itemsArray = [...state.section?.topics];
            if(index > -1)
                itemsArray[index] = action.payload.topic
            return {
                ...state,
                    section: {
                        ...state?.section,
                        topics:itemsArray
                }
            }
            

        case Constants.REMOVE_ACTIVITY:            
            return {
                ...state,
                    section: {
                        ...state?.section,                        
                        topics: state?.section?.topics.filter((x) => x.id !== action.payload.id),
                }
            }
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                sections: [],                
            }

        case Constants.CLEAR_ONE:
            return {
                section: null
            }

        default:
            return state
    }
}