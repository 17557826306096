import React, { Suspense, lazy} from 'react'
import { Route, Routes as Switch, useLocation } from 'react-router-dom'
import { Splash } from '../@uikit'
import { Fade } from '@chakra-ui/react'
import { useApp } from '../hooks'
import ScrollToTop from './ScrollToTop'
import { Redirect } from '../pages/Components'
const Login = lazy(() => import('../pages/Auth/Login'))
const ForgetPassword = lazy(() => import('../pages/Auth/ForgetPassword'))
const DabashboardLayout = lazy(() => import('../layouts/DashboardLayout'))

const Routes = () => {    
    
    const auth = useApp();
    const location = useLocation()
    
    
    if(auth.loading)
    {
        return(
            <Fade in={true}>
                <Splash />
            </Fade>
        )
    }        

    
    if( !auth.loading && (window.is_empty(auth.user) )) 
    {
        return(
            <Fade in={true} key={location?.key}>
                <Suspense fallback={<Splash />}>                    
                    <ScrollToTop />
                    <Switch>                            
                        <Route exact path="/" element={<Login/>} />                        
                        <Route exact path="/forget-password" element={<ForgetPassword/>} />
                        <Route path="*" element={<Redirect path="/" />} />                            
                    </Switch>                    
                </Suspense>
            </Fade> 
        )
    }

    if(!window.is_empty(auth?.user)){
        return(
            <Fade in={true}>                
                <Suspense fallback={<Splash />}>
                    <Switch>
                        <Route path="/*" element={<DabashboardLayout/>} />                         
                    </Switch>                                        
                </Suspense>            
            </Fade> 
        )
    }

}

export default Routes;