import { TopicConstants as Constants } from '../constants'

const initialState = {
    questions: [],    
};

export function topicReducer(state = initialState, action) {
    switch (action.type) {  
        case Constants.GET_QUESTIONS:
            return {
                ...state,
                questions: action.payload.questions
            };

        case Constants.IMPORT:
            return {
                ...state,
                questions: action.payload.questions
            };
            
        
        case Constants.FAILED:
            return{
                ...state
            }

        case Constants.CLEAR:
            return {
                questions: [],                
            }

        
        default:
            return state
    }
}