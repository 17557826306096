import React from 'react'
import { Box, IconButton, HStack, Image, FormHelperText, Text, FormControl, FormErrorMessage } from "@chakra-ui/react"
import { Icon } from '.'

export const AvatarPicker = ({size, value, label, error, showerror=true, required, info, rounded, onChange, ...rest}) => {

    const imgRef = React.useRef()

    const handleFile = (e) => {
        if(e.target.files[0])
        {
            if(e.target.files[0]?.type.includes('image/'))
            {
                let img = URL.createObjectURL(e.target.files[0])
                onChange({
                    thumb: img,
                    image: e.target.files[0]
                })                
            }
            else
            {
                alert("Please select image file")
            }
        }
    }

    const triggerSelect = () =>{
        var event = new MouseEvent('click', {
            'view': window, 
            'bubbles': true, 
            'cancelable': false
           });           
           imgRef.current.dispatchEvent(event);
    }

    const resetAvatar = () => {
        onChange({        
            thumb: null,
            image: null
        }) 
    }


    return(
        <FormControl isInvalid={error}>
            {label && <Text align="left" my="0" mb="5" fontSize="12px" color="gray.400" fontWeight="600" textTransform="uppercase">{label} {required && <Text as={'span'} m="0" fontSize="xs" color="red">*</Text>}</Text>}
            {
                showerror && <FormErrorMessage>{error}</FormErrorMessage>
            }
            {
                info && <FormHelperText>{info}</FormHelperText>
            }
            <Box position="relative"  className="hover-reveal" rounded={rounded ? "full" : "none"} boxSize={size || "120px"} {...rest}>
                <Image src={value?.thumb || "/assets/image-picker-icon.svg"} boxSize={size || "120px"} rounded={rounded ? "full" : "none"} />
                <HStack className="hover-target" display="none" pos="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
                    <IconButton onClick={triggerSelect} colorScheme="green" rounded="full" size="sm" icon={<Icon color="currentcolor" fontSize="18px" name="image-fill" />}  />
                    { value?.thumb && <IconButton onClick={resetAvatar} rounded="full" colorScheme="red" size="sm" icon={<Icon color="currentcolor" fontSize="18px" name="close-fill" />}  />}                            
                </HStack>
                <input  accept="image/*" style={{  display: "none" }} ref={imgRef} type="file" onChange={handleFile}/>                    
            </Box> 
        </FormControl>       
    )
}