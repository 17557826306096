import { TransactionConstants as Constants } from '../constants'
import { transactionApi as api } from '../../api'

export const transctionAction = {    
    get,
    add,
    update,
    remove,
    clear,
};

function get(offset, filters, page_size) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get(offset, filters, page_size)
                .then(
                    payload => {
                        dispatch(success(payload));                        
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    // function success2(payload) { return { type: CourseConstants.UPDATE_SECTIONS, payload } }
    function success(payload) { return { type: Constants.GET, payload } }    
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.ADD, payload } }    
    function failure() { return { type: Constants.FAILED } }
    }
}

function update(form_data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update(form_data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.REMOVE, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR } }
}