import { DiscountConstants as Constants } from '../constants'
import { discountApi as api } from '../../api'

export const discountAction = {
    get_coupons,
    get_vouchers,
    add_coupon,
    add_voucher,
    update_coupon,
    update_voucher,    
    remove_coupon,
    remove_voucher,
    clear_coupons,
    clear_vouchers
};

function get_coupons() {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get_coupons()
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET_COUPONS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function get_vouchers(offset, filters, page_size) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._get_vouchers(offset, filters, page_size)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.GET_VOUCHERS, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function add_coupon(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add_coupon(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.ADD_COUPON, payload } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function add_voucher(form_data) {    
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._add_voucher(form_data)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve()
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )
        });
    function success(payload) { return { type: Constants.ADD_VOUCHER, payload } }
    function failure() { return { type: Constants.FAILED } }
    }
}

function update_coupon(form_data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update_coupon(form_data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE_COUPON, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function update_voucher(form_data, id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._update_voucher(form_data, id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.UPDATE_VOUCHER, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove_coupon(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete_coupon(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DELETE_COUPON, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function remove_voucher(id) {
    return dispatch => {
        return new Promise((resolve, reject) => {
            api._delete_voucher(id)
                .then(
                    payload => {
                        dispatch(success(payload));
                        resolve(payload)
                    },
                    error => {
                        dispatch(failure('Failed'));
                        reject(error)
                    }
                )

        });
    }
    function success(payload) { return { type: Constants.DELETE_VOUCHER, payload } }
    function failure(error) { return { type: Constants.FAILED, error } }
}

function clear_coupons() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR_COUPONS } }
}

function clear_vouchers() {
    return dispatch => {   
        return new Promise((resolve, reject) => {     
            dispatch(success());
            resolve()
        })   
    }
    function success() { return { type: Constants.CLEAR_VOUCHERS } }
}