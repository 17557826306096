import React, {} from 'react'
import { HStack, Box, useOutsideClick,
    Popover, PopoverTrigger,
    PopoverContent, PopoverBody,
} from '@chakra-ui/react'
import { Icon } from '.'

export const Dropdown = ({clearable, labelStyle, labelSize, onClose,  controlled, isOpen, onClick, label,  onClear, children, ...rest}) => {

    const dropDownRef = React.useRef(null)

    useOutsideClick({
        ref: dropDownRef,
        handler: () => onClose(),
    })

    

    return(
        <div ref={dropDownRef}>
            <Popover variant="dropdown"   placement="bottom-start" {...(controlled && {isOpen: isOpen})}>
                <PopoverTrigger>
                    <Box cursor="pointer" minW="200px" alignSelf="flex-start" {...(controlled && {onClick: onClick })}>
                        <HStack spacing={1} {...labelStyle} justifyContent="space-between">
                            {label}
                            <HStack spacing={1}>
                                <Icon fontSize="18px" name="arrow-down-s-fill" color="currentcolor" />
                                {clearable && <Icon onClick={(e) => onClear(e)} _hover={{bg :"red.50"}} rounded="full" fontSize="15px" name="close-fill" color="red.500" />}
                            </HStack>
                        </HStack>
                    </Box>               
                </PopoverTrigger>
                <PopoverContent rounded="none" w="auto" minW="200px" _focus={{ shadow: 'xl' }} left="0" shadow="xl" maxH="260px" overflowY="auto">
                    <PopoverBody p={0}>
                    {children}  
                    </PopoverBody>                             
                </PopoverContent>
            </Popover>
        </div>
    )
}