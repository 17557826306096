import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { categoryAction } from '../redux/actions'

export const useCategories = () => {
    
    const dispatch = useDispatch()
    const [ parentCategories, setParentCategories ] = useState([])
    const { categories } = useSelector(state => state?.categories)  
    const [ loading, setLoading ] = useState(false)  

    const getCategories = () => {        
        setLoading(true)
        dispatch(categoryAction.get())
            .then((res) => setLoading(false))
            .catch((error) => setLoading(false))
    }
    
    useEffect(() => {
        if(categories?.length > 0){
            let filtered = categories?.filter((x) => !x?.parent_id)
            setParentCategories(filtered || [])
            return () => {
                dispatch(categoryAction.clear())
            };
        }
    }, [categories, dispatch])

            
    return [parentCategories, getCategories, loading]

}